<template>
  <!-- <dv-border-box-12> -->
    <div id="centerRight2">
      <div class="bg-color-black">
        <div class="d-fle title11">
          <!-- <span>
            <icon name="align-left" class="text-icon"></icon>
          </span> -->
          <span class="fs-xl text_title commonTitle">报废织物排名</span>
        </div>
        <div class="d-flex flex-column body-box title22">
          <!-- <dv-capsule-chart class="dv-cap-chart" :config="config" /> -->
          <!-- <centerRight2Chart1 /> -->
          <div class="d-flex d-title flexEnd">
            <!-- <div class=" d-flex jc-end fz17 bfsl"></div> -->
            <div class="d-flex jc-end fz17 commonText" style="width: 100%;">
              <div class="w50"></div>
              <div class="w25">报废数量</div>
              <div class="w25">平均洗涤寿命</div>
            </div>
          </div>
          <div class="d-flex table-list">
            <vue-seamless-scroll
              :data="listData"
              :class-option="classOption"
              class="warp"
              ref="scroll3"
            >
            <ul class="item">
              <li v-for="(item, index) in result" :key="index">
                <span class="index" >
                  <span class="orange">No.{{ index+1 }} <span class="name" v-text="item.spuName"></span></span>
                  <span class="line" :style="{ width: item.rate + '%' }"></span>
                </span>
                <span class="num" v-text="item.num"></span>
                <span class="numWashing" v-text="item.numWashing"></span>
              </li>
            </ul>
          </vue-seamless-scroll>
          </div>
        </div>
        <div class="d-flex jc-center text-info-1 fs-lg title33 commonText totalBottom">
          报废总数：{{ total }}
        </div>
      </div>
    </div>
  <!-- </dv-border-box-12> -->
</template>

<script>
// import centerRight2Chart1 from '@/components/echart/centerRight/centerRightChart'
import * as api from '@/api/index'
export default {
  data() {
    return {
      listData: [
        {
          title: "无缝滚动第一行无缝滚动第一行",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第二行无缝滚动第二行",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第三行无缝滚动第三行",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第四行无缝滚动第四行",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第五行无缝滚动第五行",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第六行无缝滚动第六行",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第七行无缝滚动第七行",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第八行无缝滚动第八行",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第九行无缝滚动第九行",
          date: "2017-12-16",
        },
      ],
      classOption: {
        singleHeight: 40,
        waitTime: 5000
      },

      total: 0,
      config: {
        waitTime: 5000,
        data: [
          {
            name: '国内',
            value: 167
          },
          {
            name: '海外',
            value: 67
          },
          {
            name: '汇总',
            value: 234
          },
        ]
      },
      colors: ['red', '#e55132', '#dd752e', '#e7a649', '#96d70f'],
      params: {
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        endTime: '',
        customerId: ''
      },
      result: [],
      intervalId: null // 用于存储定时器的 ID
    }
  },
  // components: { centerRight2Chart1 }
  mounted() {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    this.params.customerId = this.$route.query.customerId
    this.$refs.scroll3._cancle()
    // 首次加载时立即获取数据
    this.getData()
    
    // 设置定时器，每隔 30 分钟获取一次数据
    this.intervalId = setInterval(() => {
      this.getData();
    }, 30 * 60 * 1000); // 30 分钟
  },
  
  beforeDestroy() {
    // 组件销毁前清除定时器
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    async getData() {
      const res = await api.getBigSkuDispose(this.params)
      console.log(res.Result, '织物报废-----------')
      if (res.ResultCode == 0) {
        this.result = res.Result.sort(function (a, b) {
          return b.num - a.num; // 从大到小排序
        })
        this.total = res.Result.reduce((pre, ctx) => pre + ctx.num, 0)

        this.result = this.result.map(item => {
          item.rate = item.num / this.total * 100
          return item
        })
        let that = this
        setTimeout(() => {
          that.listData = that.result
        }, 5000);

        // let tempdata =  res.Result.sort(function (a, b) {
        //   return b.num - a.num; // 从大到小排序
        // }).map(v => {
        //   return {
        //     name: v.spuName,
        //     value: v.num,
        //   }
        // });

        // console.error('tempdata',tempdata)

        // this.config = { waitTime: 5000, unit: 'ㅤㅤ　', data : tempdata  }

        // console.error(this.config)

        
        // this.config.data.forEach((item,index) => {
        //   this.result.forEach((item2,index2) =>{
        //     if(index == index2){
        //       let str = '　　　　　　　　　ㅤㅤ　　　　　　ㅤㅤㅤ'
        //       item.name = item.name.replace(/[()]/g, match => match === '(' ? '（' : '）').replace(/\s+/g, '');
        //       let length = str.length -  item.name.toString().length
        //       str = '　'.repeat(length) // 'aaa'
        //       this.config.data[index].name = item.name + '' + str + item.value

        //       item.value = item2.numWashing
        //     }
        //   })
          
        // });

        // console.log(this.result, 'this.result---------')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#centerRight2 {
 // $box-height: 410px;
 // $box-width: 500px;
  //padding: 16px;
  // height: $box-height;
  width: 100%;
  height: 100%!important;
  border-radius: 5px;
  padding-bottom: 0px;

  ::v-deep .dv-scroll-ranking-board .ranking-info .ranking-value {
    // display: none;
  }

  ::v-deep  .ranking-column {
     border: none;
    margin-bottom: -5px;
    margin-top: 0px;
    .inside-column {
      background-color: #e77d35;
    }
    .row-item{
      height: 24px!important;
    }
  }

  .ul_item {
    //height: 180px;
    overflow-y: auto;

    li {
      padding:0 10px 0 10px;
      align-items: center;
      text-align: center;
      .numWashing{
        font-size: 14px;
        display: inline-block;
        width: 100%;
        color:#00cdff;
      }

      .span_t {
        width: 100px;
      }

      .item_li {
        width: 280px;
        margin-right: 5px;
      }

      .default_num {
        max-width: 260px;
        height: 15px;
        background-color: #e77d35;
        text-align: center;
      }
    }
  }

  .bg-color-black {
    // padding: 5px;
    height: 100%!important;
    // width: $box-width;
    border-radius: 10px;
    padding: 0 20px;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 0px;

    .dv-cap-chart {
      width: 100%;
      height: 160px;
    }
  }
  .jc-end{
    margin-bottom: 10px
  }
  .d-title{
    justify-content: space-between;
    //margin-top: 10px;
    height: 15%;
  }
  .table-list{
    height: 100%;
    .top_scrap{
      width: 98%;
    }
  }
  .bfsl{
    width: 70%;
    text-align: right;
  }
  .fz17{
    font-size: 14px;
  }

  .title11{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 18%;
  }
  .title22{
    height: 68%;
   
  }
  .title33{
    height: 14%;
  }
  .item-li{
    .numWashing{
      display: inline-block;
      height: 22px;
    }
  }
}
.flexEnd{
  display: flex;
  flex-direction: row;
  justify-content: flex-end!important;
  width: 98%;
}
.hide{
  opacity: 0;
}


.warp {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    li,a {
      display: block;
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      width: 100%;
      margin-bottom: 10px;
    }
    li{
      //border-bottom: 3px solid #1370fb;
    }
    .index{
      display: inline-block;
      width: 50%;
      .orange{
        color:#e77d35;
        margin-bottom: 3px;
        display: inline-block;
      }
      position: relative;
      span{
        color: #fff;display: inline-block;
      }
      .line{
        height: 6px; width:100%;background:#2d8cf0;display:inline-block;border-radius: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        min-width: 10%;
      }
    }
    .num{
      width: 25%;text-align: center;color: #00cdff;white-space:nowrap;
    }
    .numWashing{
      width: 25%;
      text-align: center;color: #00cdff;
    }
  }
}
.w50{
  width: 50%;
  text-align: center;
}
.w25{
  width: 25%;
  text-align: center;white-space:nowrap;
}
</style>
