<template>
  <div id="bottomRight">
    <div class="bg-color-black">
      <div class="d-flex title-box">
        <!-- <span>
          <icon name="chart-area" class="text-icon"></icon>
        </span> -->
        <div class="d-flex">
          <span class="fs-xl text_title mx-2 commonTitle">库存预警提醒</span>
          <div class="decoration2">
            <dv-decoration-2 :reverse="true" style="width:5px;height:6rem;" />
          </div>
        </div>
      </div>
      <div class="body-box">
        <div class="custom_table padding-xs">
          <!-- <el-table v-loading="loading" :data="warnList" show-overflow-tooltip max-height="240px">
            <el-table-column label="仓库" align="center" prop="areaName" />
            <el-table-column label="品名" align="center" prop="spuName" />
            <el-table-column label="预警数据" align="center" prop="numWaring" />
            <el-table-column label="库存数" align="center" prop="num" />
          </el-table> -->
          <ul class="header_ul d-flex fs14 text-info-1 ">
            <li>仓库</li>
            <li>品名</li>
            <li>警戒数</li>
            <li>库存数</li>
          </ul>
          <ul class="tbody_ul" v-if="warnList.length > 0">
            <li v-for="(item, index) in warnList" :key="index" class="d-flex fs14 text-info-1">
              <span>{{ item.areaName }}</span>
              <span>{{ item.spuName }}</span>
              <span>{{ item.numWaring }}</span>
              <span style="color:#e77d35;">{{ item.num }}</span>
            </li>
          </ul>
          <div v-else class="d-flex jc-center text-info-1 padding-top-sm fs14">暂无数据...</div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import * as api from '@/api/index'

// import BottomRightChart from "@/components/echart/bottom/bottomRightChart";
export default {
  components: {
    // BottomRightChart
  },
  data() {
    return {
      loading: false,
      warnList: [],
      params: {
        // pageNum: 1,
        // pageSize: 10000,
        // startTime: '',
        // endTime: '',
        customerId: ''
      },
      intervalId: null // 用于存储定时器的 ID
    }
  },
  mounted() {
    const info = JSON.parse(localStorage.getItem('userInfo'))
    this.params.customerId = this.$route.query.customerId

    this.getData()

    // 设置定时器，每隔 30 分钟获取一次数据
    this.intervalId = setInterval(() => {
      this.getData();
    }, 30 * 60 * 1000); // 30 分钟
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    async getData() {
      const res = await api.getSpuStockWarning(this.params)
      // console.log(res, '库存预警-----------')
      if (res.ResultCode == 0) {
        this.warnList = res.Result
        // console.log(this.warnList, 'this.result---------')
      }
    },
  }
};
</script>

<style lang="scss" class>
//$box-height: 315px;
//$box-width: 100%;

#bottomRight {
  //padding: 14px 16px;
  // height: $box-height;
  width: 100%;
  height: 100%;
  border-radius: 5px;

  .bg-color-black {
    height: 100%;
    border-radius: 10px;
    .title-box{
      height: 18%;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .body-box{
      height: 82%;
    }
  }

  //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }

  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;

    .active-ring-name {
      padding-top: 10px;
    }
  }

  .custom_table {
    padding-top: 20px;

    .header_ul {
      flex-flow: row nowrap;
      justify-content: space-between;

      li {
        flex: 1;
        text-align: center;
      }
    }

    .tbody_ul {
      height: 210px;
      overflow-y: auto;

      li {
        padding: 10px 0;

        span {
          flex: 1;
          text-align: center;
        }
      }
    }
  }
  .fs14{
    font-size: 14px;
  }
}
</style>